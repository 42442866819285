function ecapFormSubmit(formSubmitted) {
    if (!formSubmitted.elements.email_address.value) {
        return false
    }

    const apiGatewayKeyPublic = formSubmitted.dataset.apiGatewayKeyPublic || 'no-api-key'
    const ecappedOn = formSubmitted.dataset.ecappedOn || 'ecap-all-squeeze-paid-fb-1'
    const email = formSubmitted.elements.email_address.value || 'no-email-address'
    const foolEcapSource = formSubmitted.dataset.foolEcapSource || 'iANemcemc0010001'
    const foolEcapAid = formSubmitted.dataset.foolEcapAid || '10752'
    const formId = formSubmitted.dataset.formId || 'no-form-id'
    const confirmationText = formSubmitted.dataset.confirmationText || 'Thank you! Talk to you Soon!'
    const confirmationUrl = formSubmitted.dataset.confirmationUrl || ''
    const ftmDerby = formSubmitted.dataset.ftmDerby || ''
    const ftmHeat = formSubmitted.dataset.ftmHeat || ''

    const visitCookieValue = document.cookie.replace(/(?:^|.*\s*)Visit\s*=\s*([^]*).*$|^.*$/, '$1')
    const visitorCookieValue = document.cookie.replace(/(?:^|.*;\s*)Visitor\s*=\s*([^;]*).*$|^.*$/, '$1')

    const uid = visitorCookieValue ? visitorCookieValue.replace(/.*uid=([\w-]*).*/, '$1') : ''
    const visitorGuid = visitorCookieValue ? visitorCookieValue.replace(/.*visitor=([\w-]*).*/, '$1') : ''
    const sessionGuid = visitCookieValue ? visitCookieValue.replace(/.*visit=([\w-]*).*/, '$1') : 'no-session-guid'
    const urlParams = new URL(document.location).searchParams

    const additional_properties = {
        additional_properties: {
            ecapped_on: ecappedOn,
            capture_url: window.location.href,
            utm_campaign: urlParams.get('utm_campaign') || '',
            utm_medium: urlParams.get('utm_medium') || '',
            utm_source: urlParams.get('utm_source') || '',
            utm_content: urlParams.get('utm_content') || '',
            utm_term: urlParams.get('utm_term') || '',
            publisher: urlParams.get('publisher') || '',
            uid: uid || '',
            session_guid: sessionGuid || '',
            visitor_guid: visitorGuid || '',
        },
    }

    document.querySelector('#email_address').value = 'Submitting...'

    // Send to Fool eCap
    fetch(
        `/money/ecap/api/ecap-pub/${email}/${foolEcapSource}/${foolEcapAid}/${sessionGuid}/`,
        {
            method: 'POST',
            headers: {
                'X-API-Key': apiGatewayKeyPublic,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(additional_properties),
        },
    ).then((response) => {
        if (response.ok) {
            return response.json()
        }
        return Promise.reject(response)
    }).then((data) => { // eslint-disable-line no-unused-vars
        let pitchParamsObject = {}
        // Call this for tracking purposes, ignore response
        if (document.getElementById('pitchClickUrl')) {
            const pitchUrl = new URL(document.getElementById('pitchClickUrl').href)
            const pitchUrlParams = new URLSearchParams(pitchUrl.search)
            const pitchParams = new URL(pitchUrlParams.get('url')).searchParams
            if (ftmDerby) {
                pitchParams.set('ftm_derby', ftmDerby)
            }
            if (ftmHeat) {
                pitchParams.set('ftm_heat', ftmHeat)
            }
            pitchParamsObject = Object.fromEntries(pitchParams)
            pitchParams.set('uid', uid)
            pitchParams.set('aid', foolEcapAid)
            pitchParams.set('source', foolEcapSource)
            pitchParams.set('apikey', pitchUrlParams.get('apikey'))
            pitchParams.set('impression', pitchUrlParams.get('impression'))
            if (!confirmationUrl) {
                pitchUrl.search = pitchParams.toString()
                fetch(pitchUrl.toString(), { credentials: 'same-origin' }).then()
            }
        }

        // GA eCap Event
        if (Infotrack) {
            Infotrack.userDimensions.uid = uid
            Infotrack.track('ecap', {
                category: 'Ecap',
                label: foolEcapSource,
                ecapped_on: ecappedOn,
                form_id: formId,
                ...Infotrack.pageDimensions,
                ...Infotrack.userDimensions,
                ...pitchParamsObject,
            })
        } else {
            console.log('Infotrack Not Initialized')
        }
        if (confirmationUrl !== '') {
            window.location.replace(confirmationUrl)
        } else {
            const ele = document.getElementById(`ecapResponseDiv-${formId}`)
            ele.classList.remove('d-none')
            ele.innerHTML = `${confirmationText}`
        }
    }).catch((error) => {
        let errorMessage = 'Please enter a valid email address.'
        if (error.status !== 400) {
            errorMessage = confirmationText
        }

        const ele = document.getElementById(`ecapResponseDiv-${formId}`)
        ele.classList.remove('d-none')
        ele.style.cssText = 'color: red; font-weight: bolder;'
        ele.innerHTML = errorMessage

        document.querySelector('#email_address').value = email
    })

    return true
}

window.ecapFormSubmit = ecapFormSubmit
